import React, { useEffect, useState, useMemo } from "react";
import { Row, Col, Card } from "react-bootstrap";
import { Line } from "react-chartjs-2";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import dateFormat from "dateformat";
import Api from "../../../services/Api";

const plugins = {
  afterDraw: function (chart) {
    if (chart.data.datasets.length > 0) {
      if (chart.data.datasets[0].data.length === 0) {
        var width = chart.width,
          height = chart.height,
          ctx = chart.ctx;
        ctx.restore();
        var fontSize = (height / 160).toFixed(2);
        ctx.font = fontSize + "em sans-serif";
        ctx.textBaseline = "top";
        var text = "Nenhuma Informação Disponível",
          textX = Math.round((width - ctx.measureText(text).width) / 2),
          textY = height / 2;
        ctx.fillText(text, textX, textY);
        ctx.save();
      }
    }
  },
}

let delayed;

const options = {
  responsive: true,
  scales: {
    y: {},
    x: {},
  },
  animations: {
    radius: {
      duration: 500,
      easing: 'linear',
      loop: (context) => context.active
    }
  },
  animation: {
    onComplete: () => {
      delayed = true;
    },
    delay: (context) => {
      let delay = 0;
      if (context.type === 'data' && context.mode === 'default' && !delayed) {
        delay = context.dataIndex * 5 + context.datasetIndex * 2;
      }
      return delay;
    },
  },
  hoverRadius: 6,
  hoverBackgroundColor: 'yellow',
  interaction: {
    mode: 'nearest',
    intersect: false,
    axis: 'x'
  },
  plugins: {
    tooltip: {
      enabled: true
    },
    title: {
      display: true,
      text: '',
    }
  }
};

let width, height, gradient;
function getGradient(ctx, chartArea) {
  const chartWidth = chartArea.right - chartArea.left;
  const chartHeight = chartArea.bottom - chartArea.top;
  if (!gradient || width !== chartWidth || height !== chartHeight) {
    width = chartWidth;
    height = chartHeight;
    gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);

    gradient.addColorStop(1, "#87E8F7");
    gradient.addColorStop(0.85, "rgba(27, 93, 67, 0.8)");
    gradient.addColorStop(0.50, "rgba(27, 93, 67, 0.8)");
    gradient.addColorStop(0.15, "rgba(27, 93, 67, 0.8)");
    gradient.addColorStop(0, "#E52207");
  }
  return gradient;
}

const Grafico = ({ serial, company, reload, detailsEquip }) => {
  const { configs, equipments } = company;

  const equipamento = useMemo(() => {
    return detailsEquip.find((equip) => equip?.serialnumber === serial);
  }, [serial, detailsEquip]);

  const getColSize = () => {
    if (equipments.length === 1) return 12;
    if (equipments.length <= 4) return 6;
    return 4;
  };

  const getHeightSize = () => {
    if (equipments.length === 1) return 120;
    if (equipments.length === 2) return 140;
    if (equipments.length <= 4) return 100;
    return 160;
  };

  const [medidas, setMedidas] = useState([]);
  const [status, setStatus] = useState(null);
  const [ultimaMedida, setUltimaMedida] = useState({
    _id: "",
    serialnumber: "",
    date: "",
    idcalib: "",
    grain: "",
    humidity: "",
    temperature: "",
    peso: "",
    freqc: "",
    freqv: "",
    ph: "",
    curvavalidade: "",
    companyId: "",
    localEquipament: ""
  });

  const handleEquipment = async () => {
    try {
      let { data } = await Api.get(`/api/v1/telemetria?serial=${serial}&id=${company._id}`);

      if (Array.isArray(data) && data.length > 0) {
        const reversedData = [...data].reverse(); // Cria uma cópia e inverte para não mutar o original
        setMedidas(reversedData);
        setUltimaMedida(reversedData[reversedData.length - 1]); // O primeiro item agora é o mais recente
      }
    } catch (error) {
      console.error('Erro ao buscar medidas:', error?.message);
    }
  };

  const handleGetStatus = async () => {
    try {
      let { data } = await Api.get(`/connection/status/serial/${serial}`);
      setStatus(data?.status);
    } catch (error) {
      console.error('Erro ao buscar medidas:', error?.message);
    }
  };

  useEffect(() => {
    handleEquipment();
    handleGetStatus();
  }, [reload]);

  const isWithin15Minutes = (lastDateString) => {
    const dateWithoutZ = lastDateString.replace('Z', '');
    const lastDate = new Date(dateWithoutZ).getTime();
    const now = new Date().getTime();
    const diffMs = now - lastDate;
    const diffMinutes = diffMs / (1000 * 60);
    return diffMinutes <= 20;
  };

  const isRecent = isWithin15Minutes(ultimaMedida.date);

  return (
    <Col xs={12} sm={12} md={12} lg={12} xl={getColSize()} xxl={getColSize()} className="mb-0">
      <Card className="m-0" style={{ boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.2)" }}>

        <Card.Body>
          <Row className="g-2">
            <Col md={4} className="text-center">
              {status === "online" ?
                <label style={{ fontSize: 13, background: "#198754", color: "#f4f4f4", fontWeight: 600, borderRadius: 8, padding: "0px 8px", marginRight: 8 }}>
                  ON
                </label>
                :
                <label style={{ fontSize: 13, background: "#DC3545", color: "#f4f4f4", fontWeight: 600, borderRadius: 8, padding: "0px 8px", marginRight: 8 }}>
                  OFF
                </label>
              }

              <label style={{ fontSize: 13, color: "#8fad15", fontWeight: 600 }}>
                {ultimaMedida?.serialnumber || "--"}
              </label>

              <h6 className="mb-3 mt-1" style={{ color: "#1c4220" }}>
                {equipamento?.nameEquipament?.toUpperCase() || "--"}
              </h6>
            </Col>

            <Col md={4} className="text-center">
              <label style={{ fontSize: 13, color: "#8fad15", fontWeight: 600 }}>GRÃO</label>
              <h6 className="mb-3 mt-1" style={{ color: "#1c4220" }}>
                {ultimaMedida?.grain?.toUpperCase() || "--"}
              </h6>
            </Col>

            <Col md={4} className="text-center">
              <label style={{ fontSize: 13, color: "#8fad15", fontWeight: 600 }}>ÚLTIMA MEDIDA</label>
              <h6 className="mb-3 mt-1" style={{ color: isRecent ? "#1c4220" : "#DC3545" }}>
                {ultimaMedida?.date ? dateFormat(ultimaMedida.date, "UTC:dd/mm/yy' 'HH:MM") : "--"}
              </h6>
            </Col>
          </Row>

          <Row className="mb-0 g-2">

            <Col md={4} className="text-center">
              <Card className="mb-0">
                <Card.Body className="p-2">
                  <Card.Title style={{ color: "#8fad15", marginBottom: 0 }}>UMIDADE</Card.Title>
                  <h1 style={{ color: "#1c4220", marginBottom: 0 }}>
                    <span style={{ fontSize: "2.0rem" }}>
                      {ultimaMedida?.humidity ? Math.floor(ultimaMedida.humidity) : "--"}
                    </span>
                    <span style={{ fontSize: "1.5rem" }}>
                      {ultimaMedida?.humidity ? "." + (ultimaMedida.humidity % 1).toFixed(1).substring(2) : ""}
                    </span>
                    <span style={{ fontSize: "1.5rem" }}>{ultimaMedida.humidity ? " %" : ""}</span>
                  </h1>
                </Card.Body>
              </Card>
            </Col>

            <Col md={4} className="text-center">
              <Card className="mb-0">
                <Card.Body className="p-2">
                  <Card.Title style={{ color: "#8fad15", marginBottom: 0 }}>TEMPERATURA</Card.Title>
                  <h1 style={{ color: "#1c4220", marginBottom: 0 }}>
                    <span style={{ fontSize: "2.0rem" }}>
                      {ultimaMedida?.temperature ? Math.floor(ultimaMedida.temperature) : "--"}
                    </span>
                    <span style={{ fontSize: "1.5rem" }}>
                      {ultimaMedida?.temperature && "." + (ultimaMedida.temperature % 1).toFixed(1).substring(2)}
                    </span>
                    <span style={{ fontSize: "1.5rem" }}>{ultimaMedida.temperature ? " °C" : ""}</span>
                  </h1>
                </Card.Body>
              </Card>
            </Col>

            <Col md={4} className="text-center">
              <Card className="mb-0">
                <Card.Body className="p-2">
                  <Card.Title style={{ color: "#8fad15", marginBottom: 0 }}>{ultimaMedida.ph && Number(ultimaMedida.ph) < 200 ? "PH" : "DENSIDADE"}</Card.Title>
                  <h1 style={{ color: "#1c4220", marginBottom: 0 }}>
                    {ultimaMedida?.ph ? (
                      <>
                        <span style={{ fontSize: "2.0rem" }}>
                          {Math.floor(ultimaMedida.ph)}
                        </span>
                        <span style={{ fontSize: "1.5rem" }}>
                          .{(ultimaMedida.ph % 1).toFixed(1).split(".")[1]}
                        </span>
                        <span style={{ fontSize: "1.5rem" }}>
                          {Number(ultimaMedida.ph) < 200 ? " kg/hL" : " Kg/m³"}
                        </span>
                      </>
                    ) : (
                      "--"
                    )}
                  </h1>
                </Card.Body>
              </Card>
            </Col>

          </Row>

          <div>
            <Line
              data={{
                labels: medidas.map(data => dateFormat(data.date, "UTC:dd/mm/yy' 'HH:MM")),
                datasets: [
                  {
                    label: `MAX UMIDADE`,
                    data: medidas.map(data => configs?.maxHumidity ? configs?.maxHumidity : null),
                    borderColor: "#f2dfb1",
                    borderWidth: 2,
                    fill: true,
                    pointRadius: 1,
                    tension: 0.2,
                    datalabels: {
                      labels: {
                        title: {
                          color: "grey",
                        }
                      },
                      display: 'auto',
                      align: 'bottom',
                      font: {
                        weight: 600,
                        size: 0,
                      }
                    },
                  },
                  {
                    label: "UMIDADE",
                    backgroundColor: "#1c422040",
                    data: medidas.map((data) => (parseFloat(data?.humidityReal || data?.humidity).toFixed(1))),
                    borderColor: function (context) {
                      const chart = context.chart;
                      const { ctx, chartArea } = chart;
                      if (!chartArea) {
                        return;
                      }
                      return getGradient(ctx, chartArea);
                    },
                    borderWidth: 2,
                    fill: true,
                    pointRadius: 2,
                    tension: 0.2,
                    datalabels: {
                      labels: {
                        title: {
                          color: "grey",
                        }
                      },
                      display: 'auto',
                      align: 'end',
                      font: {
                        weight: 600,
                        size: 12,
                      },
                    },
                  },
                  {
                    label: `MIN UMIDADE`,
                    data: medidas.map(data => configs?.minHumidity ? configs?.minHumidity : null),
                    borderColor: "#f2dfb1",
                    borderWidth: 2,
                    fill: true,
                    pointRadius: 1,
                    tension: 0.2,
                    datalabels: {
                      labels: {
                        title: {
                          color: "grey",
                        }
                      },
                      display: 'auto',
                      align: 'bottom',
                      font: {
                        weight: 600,
                        size: 0,
                      }
                    },
                  }
                ],
              }}
              plugins={[ChartDataLabels, plugins]}
              options={options}
              height={getHeightSize()}
            />
          </div>
        </Card.Body>
      </Card>
    </Col >
  );
};

export default Grafico;
